/* eslint-disable import/no-anonymous-default-export */
// @flow
import Immutable from 'seamless-immutable';
import {
  LOGOUT_USER,
  GET_USER_DATA,
  SET_TOKEN,
  UPDATE_USER,
  REFRESH_TOKEN,
} from '../actions/ActionTypes';

const initialState = Immutable({
  mobile: null,
  otpVerified: false,
  email: '',
  name: null,
  city: null,
  toa: false,
  authorised: false,
  transactions: [],
  currency: null,
  token: '',
  googleToken: '',
  exp: 0,
  iat: 0,
  work: null,
  countryISO: null,
  picture: null,
  locale: null,
  country: null,
  countryCode: null,
  userName: null,
  refresh_token: '',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return Immutable.merge({ ...state, ...initialState });
    }
    case GET_USER_DATA.SUCCESS: {
      return Immutable.merge(state, action.data);
    }
    case UPDATE_USER.SUCCESS: {
      return Immutable.merge(state, action.data);
    }

    case SET_TOKEN: {
      return Immutable.merge(state, {
        token: action.token.tkn,
        refresh_token: action.token.refresh,
      });
    }
    case REFRESH_TOKEN.SUCCESS: {
      return Immutable.merge(state, {
        token: action.data.tkn,
        refresh_token: action.data.refresh,
      });
    }
    default:
      return state;
  }
};
