// @flow
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const CANCEL = 'CANCEL';
const FAILURE = 'FAILURE';

function createRequestTypes(base) {
  const res = {};
  [REQUEST, SUCCESS, FAILURE, CANCEL].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
}

export const NETWORK_INFO = 'NETWORK_INFO';
export const USER_UPLOAD_LOGO = createRequestTypes('USER_UPLOAD_LOGO');

export const SET_NEXT_NODE = 'SET_NEXT_NODE';
export const GET_NEXT_NODE = createRequestTypes('GET_NEXT_NODE');
export const GET_SHOW_MORE_NEXT_NODE = createRequestTypes(
  'GET_SHOW_MORE_NEXT_NODE',
);
export const RESET_NODE = 'RESET_NODE';
export const PUSH_NODE_DATA = 'PUSH_NODE_DATA';
export const POP_NODE_DATA = createRequestTypes('POP_NODE_DATA');
export const SET_CHAIN = 'SET_CHAIN';
export const SET_MOBILE = 'SET_MOBILE';
export const SET_ACCOUNTING_COUNTRY = 'SET_ACCOUNTING_COUNTRY';
export const SET_NAME = 'SET_NAME';
export const SET_CITY = 'SET_CITY';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_OTP_VERIFIED = 'SET_OTP_VERIFIED';
export const SET_TOA = 'SET_TOA';
export const SET_AUTH_LS = createRequestTypes('SET_AUTH_LS');
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_PICTURE = 'SET_PICTURE';
export const SET_GOOGLE_ACCESS_TOKEN = 'SET_GOOGLE_ACCESS_TOKEN';
export const SET_WORK = 'SET_WORK';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_AUTHORISED = 'SET_AUTHORISED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = createRequestTypes('UPDATE_USER');
export const GET_USER_DATA = createRequestTypes('GET_USER_DATA');
export const SEND_OTP = createRequestTypes('SEND_OTP');
export const VERIFY_OTP = createRequestTypes('VERIFY_OTP');
export const POST_DATA = createRequestTypes('POST_DATA');
export const GET_TRANSACTIONS = createRequestTypes('GET_TRANSACTIONS');
export const DELETE_TRANSACTION = createRequestTypes('DELETE_TRANSACTION');
export const REFRESH_TOKEN = createRequestTypes('REFRESH_TOKEN');
export const GET_TRANSACTION_DETAIL = createRequestTypes(
  'GET_TRANSACTION_DETAIL',
);
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';

export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_COUNTRY_ISO = 'SET_COUNTRY_ISO';
export const SET_TOKEN = 'SET_TOKEN';
