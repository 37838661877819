import {
  GET_NEXT_NODE,
  GET_SHOW_MORE_NEXT_NODE,
  POP_NODE_DATA,
  PUSH_NODE_DATA,
  RESET_NODE,
  POST_DATA,
  DELETE_SUPPLIER,
} from './ActionTypes';
import DataHandler from '../services/DataHandler';

export const getNextNodeRequest = (payload, responseCallback) => {
  return {
    type: GET_NEXT_NODE.REQUEST,
    payload,
    responseCallback,
  };
};
export const getShowMoreNextNodeRequest = (payload, responseCallback) => {
  return {
    type: GET_SHOW_MORE_NEXT_NODE.REQUEST,
    payload,
    responseCallback,
  };
};
export const getNextNodeSuccess = (data) => {
  return {
    type: GET_NEXT_NODE.SUCCESS,
    data,
  };
};
export const popNode = () => {
  return {
    type: POP_NODE_DATA,
  };
};
export const pushNode = (id, result) => {
  return {
    type: PUSH_NODE_DATA,
    data: { id, result },
  };
};
export const resetNode = () => {
  return {
    type: RESET_NODE,
  };
};
export const postData = (payload, responseCallback) => {
  let nodeData = DataHandler.getStore().getState().node;
  payload.responseArray = nodeData.data;
  return {
    type: POST_DATA.REQUEST,
    payload,
    responseCallback,
  };
};

export const deleteSupplier = (payload, responseCallback) => {
  return {
    type: DELETE_SUPPLIER,
    payload,
    responseCallback,
  };
};
