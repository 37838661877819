import {
  GET_TRANSACTIONS,
  DELETE_TRANSACTION,
  GET_TRANSACTION_DETAIL,
} from './ActionTypes';
import DataHandler from '../services/DataHandler';
import toLower from 'lodash/toLower';

export const getTransactionsRequest = () => {
  return {
    type: GET_TRANSACTIONS.REQUEST,
  };
};
export const getTransactionsSuccess = (data) => {
  const modTransactions = [];
  const transactions = [];
  console.log({ data });
  try {
    data.forEach((item) => {
      let amount = 0;
      let name = 'NA';
      const { _id, createdAt, type, glEntry } = item;
      if (item.additionalData) {
        if (item.additionalData[0].array) {
          let assigned = false;
          item.additionalData[0].array.forEach((arrayItem) => {
            const key = Object.keys(arrayItem)[0];

            if (
              (key.split('Name').join('') !== key ||
                key.split('name').join('') !== key) &&
              !assigned
            ) {
              name = item.additionalData[0][key];
              assigned = true;
            }
          });
        } else {
          Object.keys(item.additionalData).forEach((key) => {
            if (
              key.split('Name').join('') !== key ||
              key.split('name').join('') !== key
            )
              name = item.additionalData[0][key];
          });
        }
      }
      glEntry.forEach((glElement) => {
        glElement.options.forEach((element) => {
          if (
            element.name === 'creditLabel' ||
            element.name === 'transactionAmount'
          ) {
            console.log({ element });
          }
          if (element.value === 'BLANK') {
            element.value = '--';
          }
          if (element.name === 'transactionAmount') {
            amount += parseFloat(element.value.split(',').join(''));
          }
        });
      });

      const modItem = [
        { amount: amount / 2, name, type: toLower(type), date: createdAt, _id },
      ].concat(item);
      transactions.push(modItem);
    });
  } catch (error) {
    console.log(error);
  }

  return {
    type: GET_TRANSACTIONS.SUCCESS,
    transactions,
  };
};

export const deleteTransactionsRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: DELETE_TRANSACTION.REQUEST,
  };
};
export const deleteTransactionsSuccess = (ids) => {
  return {
    ids,
    type: DELETE_TRANSACTION.SUCCESS,
  };
};
export const getTransactionDetailsRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: GET_TRANSACTION_DETAIL.REQUEST,
  };
};
export const getTransactionDetailsSuccess = (ids) => {
  return {
    ids,
    type: GET_TRANSACTION_DETAIL.SUCCESS,
  };
};
