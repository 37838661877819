/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      // eslint-disable-next-line react/no-unused-state
      errorInfo: null,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error, info) {
    this.setState({ error, errorInfo: info });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { errorInfo, error } = this.state;
    const { children } = this.props;
    if (errorInfo) {
    
      return (
        <div>
          <b>Sorry, There was some error    .{errorInfo.componentStack}</b>
          <Link to="/home">Go Home</Link>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
