import React from 'react';
import { Provider } from 'react-redux';

import Routing from './router/index';
import configureStore from './store';
import DataHandler from './services/DataHandler';
import { persistStore } from 'redux-persist';
import './App.css';
const reducers = require('./reducers').default;

export default class App extends React.Component {
  persistor = {};
  state = {
    isLoading: true,
    store: configureStore(reducers, () => {
      this._loadingCompleted();
      this.setState({ isLoading: false });
    }),
  };

  _loadingCompleted = () => {
    DataHandler.setStore(this.state.store);
    // Util.getCsrfToken();
    this.persistor = persistStore(this.state.store);
  };

  render() {
    // if (this.state.isLoading) {
    //   return <div className="loading">Loading...</div>;
    // }

    return (
      <Provider store={this.state.store}>
        <React.StrictMode>
          <Routing />
          {/* <MuiPickersUtilsProvider utils={DayJSUtils}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <ErrorBoundary>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/register" />
                      </Route>
                      <Route path="/home" component={Home} />
                      <Route path="/register" component={Register} />
                      <Route path="/add" component={AddTransaction} />
                      <Route path="/transaction/:id" component={Transaction} />
                      <Route component={() => <Redirect to="/home" />} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </BrowserRouter>
            </ThemeProvider>
          </MuiPickersUtilsProvider> */}
        </React.StrictMode>
      </Provider>
    );
  }
}

// const Loading = () => {
//   return <div className="loading">Loading...</div>;
// };

// const App: React.FC = (): JSX.Element => {
//   const [someStore, setStore] = useState({});
//   const [loading, setLoading] = useState(true);

//   setStore(configureStore(reducers ,()=>{
//     setLoading(false)
//   }))
//   const dispatch = useDispatch();
//   if(!_.isEmpty(tkn)){
//     dispatch(setToken(tkn))
// }
// if(loading){
//   return <Loading/>
// }
//   return (
//     <Provider store={someStore}>
//     <React.StrictMode>
//     <MuiPickersUtilsProvider utils={DayJSUtils}>
//     <ThemeProvider theme={theme}>
//       <BrowserRouter>
//         <ErrorBoundary>
//           <Suspense fallback={<Loading />}>
//             <Switch>
//               <Route exact path="/">
//                 <Redirect to="/register" />
//               </Route>
//               <Route path="/home" component={Home} />
//               <Route path="/register/:tkn" component={Register} />
//               <Route path="/register" component={Register} />
//               <Route path="/add" component={AddTransaction} />
//               <Route path="/transaction/:id" component={Transaction} />
//               <Route component={() => <Redirect to="/home" />} />
//             </Switch>
//           </Suspense>
//         </ErrorBoundary>
//       </BrowserRouter>
//     </ThemeProvider>
//     </MuiPickersUtilsProvider>
//     </React.StrictMode>
//     </Provider>
//   );
// };

// export default App;
