/* eslint-disable import/no-anonymous-default-export */
// @flow
import Immutable from 'seamless-immutable';
import {
  GET_USER_DATA,
  UPDATE_USER,
  GET_NEXT_NODE,
  PUSH_NODE_DATA,
  POP_NODE_DATA,
  RESET_NODE,
  LOGOUT_USER,
} from '../actions/ActionTypes';

const initialState = Immutable({
  node: null,
  chainName: 'Default Import Chain',
  chainId: null,
  data: [],
  // chainName: 'India',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEXT_NODE.SUCCESS: {
      return Immutable.merge(state, {
        node: action.data.node,
        chainId: action.data.chainId,
        chainName: action.data.chainName,
      });
    }
    case UPDATE_USER.SUCCESS: {
      return Immutable.merge(state, action.data);
    }
    case PUSH_NODE_DATA: {
      return Immutable.merge(state, { data: state.data.concat(action.data) });
    }
    case POP_NODE_DATA: {
      return Immutable.merge(state, {
        data: state.data.filter((_, index) => index !== state.data.length - 1),
      });
    }
    case RESET_NODE: {
      return Immutable.merge(state, {
        data: [],
        node: null,
      });
    }
    case LOGOUT_USER: {
      return Immutable.merge({ ...state, ...initialState });
    }
    default:
      return state;
  }
};
