import { DEV_ENV } from '../constants';
import { getServer } from '../services/Util';
import Util from '../services/Util';
import _ from 'lodash';

export const BASE_URL = getServer();

// export const BASE_URL = 'https://dev.access-loan.com/api/v1';

// export const BASE_URL = "https://api.kiffgo.com/";

// const MAP_KEY = "";

export const MAP_KEY = '';

export const G_API_URL = ``;
export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = 'Network failed. Aborted request.';

// API USER ROUTES
// export const API_LOG = process.env.REACT_APP_ENV === DEV_ENV;
export const API_LOG = true;

export const ERROR_SOMETHING_WENT_WRONG = {
  message: 'Something went wrong, Please try again later',
};
export const ERROR_NETWORK_NOT_AVAILABLE = {
  message: 'Please connect to working internet',
};

export const ERROR_TIMEOUT = {
  message: 'Request timeout, please check you internet!',
};

export const ERROR_SESSION_EXPIRED = {
  message: 'Session expired, please refresh',
};

export const REQUEST_TYPE = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put',
};

// API USER ROUTES

export const GET_USER_DATA = {
  route: '/user',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const GET_TRANSACTIONS = {
  route: '/transaction/unformatted',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const GET_NEXT_NODE = {
  route: '/chain/node',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const UPDATE_USER = {
  route: '/user',
  auth: true,
  type: REQUEST_TYPE.PUT,
};
export const POST_DATA = {
  route: '/transaction/save-result-from-array',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const DELETE_TRANSACTION = {
  route: '/transaction/delete',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};
export const GET_TRANSACTION_DETAIL = {
  route: '/transaction/find-multiple',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const USER_UPLOAD_LOGO = {
  route: 'b/user/logo-upload',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const USER_SIGNIN = {
  route: 'b/login-website',
  auth: false,
  type: REQUEST_TYPE.POST,
};
export const LOGOUT_USER = {
  route: 'b/login-website',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const DELETE_SUPPLIER_NAME = {
  route: '/lookup',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};

export const callRequest = function (
  url,
  data,
  parameter,
  header = {},
  ApiSauce,
  baseUrl = BASE_URL,
) {
  console.log(data,parameter);
  const _url =
    parameter && !_.isEmpty(parameter)
      ? `${url.route}/${parameter}`
      : url.route;
  if (url.auth) {
    header.Authorization = `Bearer  ${Util.getCurrentUserAccessToken()}`;
  }
  if (url.type === REQUEST_TYPE.POST) {
    //data._csrf = Util.getCurrentCsrfToken();
    return ApiSauce.post(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.GET) {
    return ApiSauce.get(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.PUT) {
    // data._csrf = Util.getCurrentCsrfToken();
    return ApiSauce.put(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.DELETE) {
    return ApiSauce.delete(_url, data, header, baseUrl);
  }
  // return ApiSauce.post(url.route, data, _header);
};
