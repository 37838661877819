import { Images } from '../theme';
import { Route } from 'react-router-dom';

export const SAGA_ALERT_TIMEOUT = 500;
export const IMAGE_MAX_WIDTH = 500;
export const IMAGE_MAX_HEIGHT = 500;
export const MILES_LIMIT = 50;

// URLS
export const URL_WEBSITE = '';
// Days of week
export const WEEK = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
};

// date time formats
export const DATE_FORMAT1 = 'Do MMMM YYYY, HH:mm';
export const DATE_FORMAT2 = 'Do MMM';
export const DATE_FORMAT3 = 'Do MMM YYYY';
export const DATE_FORMAT4 = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT1 = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FORMAT2 = 'MMM D HH A';
export const DATE_TIME_FORMAT3 = 'HH:mm DD-MM-YYYY';
export const DATE_TIME_FORMAT4 = 'dddd Do MMM, YYYY @ HH:mm';
export const DATE_TIME_FORMAT5 = 'dddd Do MMM, YYYY @ hh: mm a';
export const DATE_TIME_FORMAT6 = 'D MMM YYYY at HH: mm';
export const DATE_TIME_FORMAT7 = 'HH:mm (DD MMM)';
export const DATE_TIME_FORMAT_BULK = 'DD/MM/YYYY HH:mm';

export const TIME_FORMAT1 = 'HH:mm';
export const TIME_FORMAT2 = 'H [h] : mm [min]';
export const TIME_FORMAT3 = 'hh A';
export const TIME_FORMAT4 = 'hh:mm A';
export const TIME_DAY_FORMAT3 = 'ddd - HH:mm';
export const TIME_ZONE = (-1 * new Date().getTimezoneOffset()) / 60;
export const SERVER_TIME_ZONE = 'Europe/London';

// Messages

export const DRIVER_SCHEDULE_INVALID_TIME_RANGE = 'Invalid time range.';
export const DRIVER_SCHEDULE_INVALID_TIME = 'Invalid time.';
export const INVALID_NAME_ERROR = 'Invalid name';
export const INVALID_ADDRESS_ERROR = 'Invalid address';
export const INVALID_EMAIL_ERROR = 'Invalid email';
export const INVALID_PASSWORD = 'Use 6 characters or more for your password';
export const CONFRIM_PASSWORD_MISMATCH_ERROR = 'Confirm password mismatch';
export const INTERNET_ERROR = 'Please connect to the working internet';
export const SESSION_EXPIRED_ERROR = 'Session expired, Please login again';
export const SOMETHING_WRONG = 'Something went wrong';
export const INVALID_POSTCODE_ERROR = 'Invalid post code';
export const INVALID_WEBSITE_ERROR = 'Invalid website';
export const PASSWORD_RESET_SUCCESS_MSG =
  'Your password has been reset successfully. Use this new password to get logged-in again and enjoy our hassle free services.';
export const IS_REQUIRED_ERROR = 'This field is required';
export const PHONE_IS_REQUIRED_ERROR = 'Phone field is required';
export const NAME_IS_REQUIRED_ERROR = 'Name field is required';
export const ADDRESS_IS_REQUIRED_ERROR = 'Address field is required';
export const INVALID_PHONE_ERROR = 'Invalid phone number';
export const PASSED_TIME_ERROR = 'You cannot select past date or time';
export const COPIED_TO_CLIPBOARD_MESSAGE = 'Copied to Clipboard';
export const SEND_INVOICE_SUCCESSFULLY = 'Invoice sent successfully';
export const ARE_YOU_SURE = 'Are you sure?';
export const INVALID_LAT = 'Invalid latitude';
export const INVALID_LNG = 'Invalid longitude';
export const FAILED_TO_FETCH = 'Failed to fetch, try to refresh the page';
export const DEV_ENV = 'dev';
export const PROD_ENV = 'prod';
export const MAX_ADDITIONAL_LOCATION = 31;
export const MAX_IMAGES_PER_LOCATION = 3;
export const MIN_LOADING_TIME_IN_MINUTES = 10;
export const MAX_LOADING_TIME_IN_MINUTES = 180;
export const CENTRAL_LONDON = { lat: 51.509099, lng: -0.126541 };
export const DM_MODULE_UPDATE_TIMEOUT_IN_MINUTES = 20;

export const EMAIL_REGEX =
  '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/';
