import ReactDOM from 'react-dom';
import App from './App';

// Remove serviceWorker if it is registered
navigator?.serviceWorker?.getRegistrations().then(function (registrations) {
  if (registrations) {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  }
});
ReactDOM.render( <App />,document.getElementById('root'));



