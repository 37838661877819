import DataHandler from '../services/DataHandler';
import _ from 'lodash';

const makeUSerData = (data) => {
  const oldUser = DataHandler.getStore().getState().user;
  const user = {};
  user.mobile = data?.user?.number || oldUser.mobile;
  user.otpVerified = oldUser.otpVerified;
  user.email = data?.user?.email || oldUser.email;
  user.name = data?.user?.profile?.name || oldUser.name;
  user.picture = data?.user?.profile?.picture || oldUser.picture;
  user.city = data?.user?.profile?.location || oldUser.city;
  user.toa = data?.user?.profile?.toa || oldUser.toa;
  user.authorised = oldUser.authorised;
  user.token = oldUser.token;
  user.accountingCountry =
    data?.user?.accountingCountry || oldUser.accountingCountry;
  user.currency = data?.user?.currency || oldUser.currency;
  user.googleToken = data?.user?.tokens[0] || oldUser.googleToken;
  user.exp = data.exp || oldUser.exp;
  user.iat = data.iat || oldUser.iat;
  user.work = data?.user?.profile?.work || oldUser.work;
  user.countryISO = data?.user?.countryISO || oldUser.countryISO;
  user.locale = data?.user?.locale || oldUser.locale;
  user.country = data?.user?.country || oldUser.country;
  user.countryCode = data?.user?.countryCode || oldUser.countryCode;
  user.userName = data?.user?._id;
  user.authorised = true;

  return user;
};
const isLoggedIn = (data) => {
  let bol = !_.isEmpty(
    DataHandler.getStore().getState().user.data.access_token,
  );
  let ddd = DataHandler.getStore().getState().user.data;

  return bol;
};

const getUserFullName = () => {
  if (isLoggedIn()) {
    const userData = DataHandler.getStore().getState().user.data;
    return `${userData.firstName} ${userData.lastName}`;
  }
  return '';
};
const getUserFirstLetter = () => {
  if (isLoggedIn()) {
    const userData = DataHandler.getStore().getState().user.data;

    return `${userData.firstName.charAt(0).toUpperCase()}${userData.lastName
      .charAt(0)
      .toUpperCase()}`;
  }

  return '';
};
const getUserWebhookSecret = () => {
  if (isLoggedIn()) {
    return DataHandler.getStore().getState().user.data.business.webhook_key;
  }

  return '';
};

// const userLogout = () => {
//   DataHandler.getStore().dispatch(setLoggedOutRecently(true));
//   DataHandler.getStore().dispatch(userLogoutRequest());
// };

export {
  isLoggedIn,
  getUserFullName,
  getUserFirstLetter,
  getUserWebhookSecret,
  makeUSerData,
};
