import { take, put, call, fork } from 'redux-saga/effects';
import {
  GET_TRANSACTIONS,
  DELETE_TRANSACTION,
  GET_TRANSACTION_DETAIL,
} from '../actions/ActionTypes';
import { SAGA_ALERT_TIMEOUT, SOMETHING_WRONG } from '../constants';
import {
  getTransactionsSuccess,
  getTransactionDetailsSuccess,
  deleteTransactionsSuccess,
} from '../actions/TransactionsActions';

import {
  GET_TRANSACTIONS as GET_TRANSACTIONS_URL,
  DELETE_TRANSACTION as DELETE_TRANSACTION_URL,
  GET_TRANSACTION_DETAIL as GET_TRANSACTION_DETAIL_URL,
  callRequest,
} from '../config/WebService';
import ApiSauce from '../services/ApiSauce';
import { makeUSerData } from '../helpers/userHelper';

function alert(message, type = 'error') {
  setTimeout(() => {}, SAGA_ALERT_TIMEOUT);
}

function* getTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_TRANSACTIONS.REQUEST);

    try {
      const response = yield call(
        callRequest,
        GET_TRANSACTIONS_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getTransactionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getTransactionDetails() {
  while (true) {
    const { payload, responseCallback } = yield take(
      GET_TRANSACTION_DETAIL.REQUEST,
    );

    try {
      const response = yield call(
        callRequest,
        GET_TRANSACTION_DETAIL_URL,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getTransactionDetailsSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* deleteTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(
      DELETE_TRANSACTION.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        DELETE_TRANSACTION_URL,
        payload,
        '',
        {},
        ApiSauce,
      );

      if (response.length > 0) {
        yield put(deleteTransactionsSuccess(payload));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

export default function* root() {
  yield fork(getTransactions);
  yield fork(getTransactionDetails);
  yield fork(deleteTransactions);
}
