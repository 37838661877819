import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#003A63',
    },
    secondary: {
      main: '#2A719F',
    },
    error: {
      main: '#E74C3C',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
});

export default theme;
