import {
  LOGOUT_USER,
  SET_MOBILE,
  SET_NAME,
  SET_WORK,
  UPDATE_USER,
  SEND_OTP,
  SET_COUNTRY_CODE,
  SET_AUTH_LS,
  SET_TOA,
  VERIFY_OTP,
  GET_TRANSACTIONS,
  GET_USER_DATA,
  SET_TOKEN,
  REFRESH_TOKEN,
  SET_ACCOUNTING_COUNTRY,
} from './ActionTypes';
import lodashFind from 'lodash/find';
const countries = require('../country.json');

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
export const setMobile = (mobile) => {
  return {
    type: SET_MOBILE,
    mobile,
  };
};
export const setAccountingCountry = (accountingCountry) => {
  return {
    type: SET_ACCOUNTING_COUNTRY,
    accountingCountry,
  };
};
export const setName = (name) => {
  return {
    type: SET_NAME,
    name,
  };
};
export const setWork = (work) => {
  return {
    type: SET_WORK,
    work,
  };
};

export const sendOTP = (data) => {
  return {
    type: SEND_OTP.REQUEST,
    data,
  };
};
export const verifyOTP = (data) => {
  return {
    type: VERIFY_OTP.REQUEST,
    data,
  };
};
export const setCountryCode = (code) => {
  return {
    type: SET_COUNTRY_CODE,
    code,
  };
};
export const setAuthLS = (code) => {
  return {
    type: SET_AUTH_LS.REQUEST,
    code,
  };
};
export const setToa = (val) => {
  return {
    type: SET_TOA,
    val,
  };
};

export const getTransactionRequest = (val) => {
  return {
    type: GET_TRANSACTIONS.REQUEST,
    val,
  };
};
export function getUserDataRequest(responseCallback) {
  return {
    responseCallback,
    type: GET_USER_DATA.REQUEST,
  };
}
export function getUserDataSuccess(data) {
  return {
    data,
    type: GET_USER_DATA.SUCCESS,
  };
}
export function setToken(token) {
  return {
    token,
    type: SET_TOKEN,
  };
}
export function updateUserRequest(data, responseCallback) {
  const payload = {};
  const user = { profile: {} };
  if (data.name) {
    user.profile.name = data.name;
  }
  if (data.picture) {
    user.profile.picture = data.picture;
  }
  if (data.work) {
    user.profile.work = data.work;
  }
  if (data.city) {
    user.profile.location = data.city;
  }
  if (data.mobile) {
    user.number = data.mobile;
  }
  if (data.accountingCountry) {
    user.accountingCountry = data.accountingCountry;
  }
  if (data.picture) user.profile.picture = data.picture;
  if (data.toa) user.profile.toa = data.toa;
  if (data.countryISO) {
    const country = lodashFind(countries, { alpha3: data.countryISO });
    user.country = country.name;
    user.locale = country.default_locale;
    user.currency = country.currencies[0];
    user.countryCode = country.countryCallingCodes[0];
    user.countryISO = country.alpha3;
  }
  payload.user = user;
  debugger;
  return {
    type: UPDATE_USER.REQUEST,
    payload,
    responseCallback,
  };
}
export function updateUserSuccess(data) {
  return {
    data,
    type: UPDATE_USER.SUCCESS,
  };
}
export function refreshTokenSuccess(data) {
  return {
    data,
    type: REFRESH_TOKEN.SUCCESS,
  };
}
