/* eslint-disable import/no-anonymous-default-export */
// @flow
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  GET_TRANSACTIONS,
  DELETE_TRANSACTION,
  LOGOUT_USER,
} from '../actions/ActionTypes';

const initialState = Immutable({
  transactions: [],
  // chainName: 'India',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS.SUCCESS: {
      return Immutable.merge(state, {
        transactions: action.transactions,
      });
    }
    case DELETE_TRANSACTION.SUCCESS: {
      const temTransactions = _.cloneDeep(state.transactions);

      action.ids.forEach((id) => {
        const deletedIndex = _.findIndex(temTransactions, (item) => {
          const test = item[0]._id === id;
          return test;
        });
        temTransactions.splice(deletedIndex, 1);
      });

      return Immutable.merge(state, {
        transactions: temTransactions,
      });
    }
    case LOGOUT_USER: {
      return Immutable.merge({ ...state, ...initialState });
    }
    default:
      return state;
  }
};
